.your-selection {
    -moz-box-shadow:    inset 0 0 10px #d6d4d4;
    -webkit-box-shadow: inset 0 0 10px #d6d4d4;
    box-shadow:         inset 0 0 10px #d6d4d4;
    border-radius: 3px;
    padding: 3px;
    text-align: center;

    max-height: 250px;
    overflow-y: scroll;
}